<template>
  <div :class="{flex: variant === 'horizontal',}">
    <ul class="bg-white mt-3 shadow-t3 rounded" :class="{ flex: variant === 'vertical',}">
      <li class="" v-for="(tab, index) in tabList" :key="index">
        <label class="text-gray-400 inline-block p-4 hover:text-t3 focus:outline-none cursor-pointer" :class="{' text-t3 border-b-2 border-t3-secondary dark:text-white dark:border-t3-secondary': index + 1 === activeTab ,}" :for="`${_uid}${index}`" v-text="tab" />
        <input style="-webkit-appearance: none; -moz-appearance: none; appearance: none;"
          :id="`${_uid}${index}`"
          type="radio"
          :name="`${_uid}-tab`"
          :value="index + 1"
          v-model="activeTab"
        />
      </li>
    </ul>

    <template v-for="(tab, index) in tabList">
      <div class="bg-white mt-5 p-4 shadow-t3 rounded" :key="index" v-if="index + 1 === activeTab">
        <slot :name="`tabPanel-${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: () => "vertical",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },
};
</script>

<style>
/* .flex {
  display: flex;
} */
</style>